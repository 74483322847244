
import Vue from 'vue';

import { IViewsConfig } from '@/types';

import GenerationTemplate from '@/components/modals/GenerationTemplate.vue';

export default Vue.extend({
    name: 'ViewsManagement',

    components: { GenerationTemplate },

    data() {
        return {
            viewConfigDataDisable: {
                min_views: 75,
                max_views: 150,
                is_active: false,
                days: 5,
            } as IViewsConfig,

            viewConfigDataEnable: {
                min_views: 75,
                max_views: 150,
                is_active: false,
                days: 7,
            } as IViewsConfig,

            ViewsManagementDisable: {
                managerType: 'view',
                id: 1,
                title: 'Еще не активированные мечты',
                description: `
                    Будет произведена генерация 
                    просмотров всех мечт, попадающих в общий 
                    каталог, но не активированных, в указанном 
                    диапазоне за указанное количество суток.
                `,
                range: 'Диапазон количества просмотров для генерации:',
                modifyTime: 'Время на генерацию (в сутках)',
                isRunning: 'Генерация просмотров запущена',
                modifySettings: 'Обновить настройки генерации',
            },

            ViewsManagementEnable: {
                managerType: 'view',
                id: 2,
                title: 'Активированные мечты',
                description: `
                    Будет произведена генерация 
                    просмотров всех активированных 
                    мечт в указанном диапазоне за 
                    указанное количество дней.
                `,
                range: 'Диапазон количества просмотров для генерации:',
                modifyTime: 'Время на генерацию (в сутках)',
                isRunning: 'Генерация просмотров запущена',
                modifySettings: 'Обновить настройки генерации',
            },
        };
    },
});
